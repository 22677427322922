<template>
  <div class="game">
    <div class="game-main">
      <div class="table_wrapper">
        <table>
          <thead>
            <tr>
              <th>№</th>
              <th v-for="(row, index) in this.list.numbers" :key="index">
                {{ index + 1 }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in this.table" :key="index">
              <td>
                <b>{{ index + 1 }}</b>
              </td>
              <td v-for="col in row" :key="col.index">
                {{ col }}
              </td>
            </tr>
            <tr>
              <td>=</td>
              <td
                v-for="(row, index) in this.list.numbers"
                :key="'td_input_' + (index + 1)"
              >
                <input
                  :key="'input_' + (index + 1)"
                  v-model="answer[index]"
                  :class="{
                    'bg-danger text-light': isChecked(index) && !isTrue(index),
                    'bg-success text-light': isChecked(index) && isTrue(index),
                  }"
                  :disabled="isChecked(index) || isFinished"
                  :name="'input' + index"
                  :tabindex="index"
                  class="form-control text-center"
                  type="number"
                  @keyup.enter="changed(index, answer[index])"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        id="resizable"
        :class="{ active: isFinished }"
        class="modal-content ui-widget-content"
      >
        <div class="modal-header ui-widget-header">
          <span>
            Jami Savollar:<span class="all_questions">
              {{ list.settings.number_count }} ta
            </span>
          </span>
          <span>
            To'g'ri Javoblar:<span class="true_answers"
              >{{ list.complete.answer }} ta
            </span>
          </span>
          <span>
            Sarflangan Vaqt:
            <span class="time">
              {{ list.settings.number_speed - display_time }}
            </span>
          </span>
        </div>
        <div class="modal-body">
          <table border="1">
            <thead>
              <tr>
                <th colspan="2">Noto'g'ri javoblar</th>
              </tr>
              <tr>
                <th>№</th>
                <th>=</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="answer in wrong_answers" :key="answer.index">
                <td>{{ answer.index + 1 }}</td>
                <td>{{ answer.result }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="game-footer">
      <div class="container game-footer__inner">
        <div class="game-footer__left">
          <div class="game-footer__points">
            <span>
              {{ list.complete.answer }}
              /
              {{ list.settings.number_count }}
            </span>
            <span class="fas fa-clock"> : {{ display_time }} </span>
          </div>
        </div>

        <div class="game-footer__right">
          <router-link :to="{ name: 'home' }" class="btn-icon">
            <i class="fas fa-home"></i>
          </router-link>
          <router-link :to="{ name: 'list_plus_settings' }" class="btn-icon">
            <i class="fas fa-cogs"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "start",
  data: () => {
    return {
      answer: [],
      checked: [],
      answer_list: [],
      wrong_answers: [],
      display_time: 0,
      interval: "",
      isFinished: false,
    };
  },
  watch: {
    not_answered: function (val) {
      if (val === 0) {
        this.submit();
      }
    },
  },
  computed: {
    not_answered: function () {
      return this.list.numbers.length - this.checked.length;
    },
    list: function () {
      return this.$store.getters["plus/state"].list;
    },
    table: function () {
      let numbers = this.list.numbers;
      const transpose = (matrix) => {
        let [row] = matrix;
        return row.map((value, column) => matrix.map((row) => row[column]));
      };
      return transpose(numbers);
    },
  },
  methods: {
    setFocus(index) {
      let element = document.getElementsByName("input" + index)[0];
      if (element !== undefined) {
        element.focus();
      }
    },
    submit: async function () {
      if (!this.isFinished) {
        clearInterval(this.interval);
        this.isFinished = true;
        if (await this.$store.dispatch("plus/listComplete")) {
          this.$toast.success("Saqlandi");
        }
      }
    },
    start() {
      this.display_time = this.list.settings.number_speed;
      this.interval = setInterval(() => {
        if (this.display_time === 0) {
          clearInterval(this.interval);
          this.submit();
        } else {
          this.display_time--;
        }
      }, 1000);
    },
    isTrue(index) {
      return (
        this.answer_list.find((element) => {
          return element === index;
        }) !== undefined
      );
    },
    async check(index, answer) {
      let original_answer = parseInt(
        this.list.numbers[index].reduce((a, b) => a + b, 0)
      );
      let result = original_answer === parseInt(answer);

      if (result) {
        await this.$store.commit("plus/INC_LIST_ANSWER");
        this.answer_list.push(index);
      } else {
        this.wrong_answers.push({ index: index, result: original_answer });
      }
      return result;
    },
    changed(index, answer) {
      if (!this.isChecked(index)) {
        this.check(index, answer);
        this.checked.push(index);
      } else {
        alert("Hakerlik qilmang!");
      }
      this.setFocus(index + 1);
    },
    isChecked(index) {
      return (
        this.checked.find((element) => {
          return element === index;
        }) !== undefined
      );
    },
  },
  async created() {
    if (!this.list.isReady) {
      await this.$router.push({ name: "list_plus_settings" });
    }
    this.start();
    // eslint-disable-next-line no-undef
    $(function () {
      // eslint-disable-next-line no-undef
      $(".modal-content").draggable({
        handle: ".modal-header",
      });
      // eslint-disable-next-line no-undef
      $("#resizable").resizable();
    });
  },
  async beforeDestroy() {
    clearInterval(this.interval);
    await this.$store.commit("plus/CLEAR_DATA");
  },
};
</script>

<style scoped>
@media (max-width: 600px) and (min-width: 400px) {
  .wrong_answers {
    font-size: 0.7em;
  }
}

@media (min-width: 601px) {
  .wrong_answers {
    font-size: 0.2em;
  }
}

.numbers {
  display: block;
}

.game-main {
  align-items: normal;
  max-width: 100%;
  max-height: 80%;
  overflow: auto;
  background: white;
}

.table_wrapper {
  padding: 2px;
}
</style>
